import { Card, List } from "antd";
import { uniq } from "lodash";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";

import { __DEPRECATED__ErrorHandler, Button, notification } from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";
import { RISK_LEVELS } from "@gfw/core";

import { Page, PageHeader } from "@src/resources/generic/components";

import CounterpartiesConflictsResolver from "./CounterpartiesConflictsResolver";
import OverviewMerge from "./OverviewMerge";
import QuestionnaireConflictsResolver from "./QuestionnaireConflictsResolver";

const getDelta = (previousInfo, newInfo) => {
  const delta = {};

  for (const x in newInfo) {
    if (JSON.stringify(previousInfo[x]) !== JSON.stringify(newInfo[x])) {
      delta[x] = newInfo[x];
    }
  }
  for (const x in previousInfo) {
    if (
      JSON.stringify(previousInfo[x]) !== JSON.stringify(newInfo[x]) &&
      delta[x] === undefined
    ) {
      delta[x] = previousInfo[x];
    }
  }
  return delta;
};

function NewProfilePage() {
  const { profileIdToRemove, profileIdToKeep } = useParams();
  const navigate = useNavigate();
  const [informationToKeep, setInformationToKeep] = useState({});
  const [relationsToUpdate, setRelationsToUpdate] = useState([]);
  const [step, setStep] = useState(0);

  const setInformation = (key, value) => {
    return setInformationToKeep({ ...informationToKeep, [key]: value });
  };

  const { data: profileToRemove, isLoading: isProfileToRemoveLoading } =
    useQuery({
      queryKey: ["profileToRemove", profileIdToRemove],
      queryFn: async () => {
        const response = await apiClient.get(`/profiles/${profileIdToRemove}`);
        return response.data;
      },
      onError: __DEPRECATED__ErrorHandler,
      refetchOnWindowFocus: false,
    });

  const {
    data: profileToRemoveCounterparties,
    isLoading: isProfileToRemoveCounterpartiesLoading,
  } = useQuery({
    queryKey: ["profileToRemoveCounterparties", profileIdToRemove],
    queryFn: async () => {
      const response = await apiClient.get(
        `/profiles/${profileIdToRemove}/mergeInfo/counterparties`,
      );
      return response.data;
    },
    onError: __DEPRECATED__ErrorHandler,
    refetchOnWindowFocus: false,
  });

  const { data: profileToKeep, isLoading: isProfileToKeepLoading } = useQuery({
    queryKey: ["profileToKeep", profileIdToKeep],
    queryFn: async () => {
      const response = await apiClient.get(`/profiles/${profileIdToKeep}`);
      return response.data;
    },
    onError: __DEPRECATED__ErrorHandler,
    refetchOnWindowFocus: false,
  });

  const {
    data: profileToKeepCounterparties,
    isLoading: isProfileToKeepCounterpartiesLoading,
  } = useQuery({
    queryKey: ["profileToKeepCounterparties", profileIdToKeep],
    queryFn: async () => {
      const response = await apiClient.get(
        `/profiles/${profileIdToKeep}/mergeInfo/counterparties`,
      );
      return response.data;
    },
    onError: __DEPRECATED__ErrorHandler,
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: onMerge } = useMutation(
    async () => {
      const filteredRelations = relationsToUpdate.filter(
        (relation) =>
          relation.risk !== relation[`${profileIdToKeep}Risk`] ||
          JSON.stringify(uniq(relation.tags).sort()) !==
            JSON.stringify(uniq(relation[`${profileIdToKeep}Tags`]).sort()),
      );
      delete informationToKeep.listUBO;
      delete informationToKeep.boardMemberWarning;
      const informationToSave = getDelta(
        profileToKeep.information,
        informationToKeep,
      );

      await apiClient.post(`/profiles/${profileIdToRemove}/merge`, {
        profileId: profileIdToKeep,
        information: informationToSave,
        relations: filteredRelations.map(
          ({ counterpartyId, counterpartyOId, risk, tags, type }) => ({
            counterpartyId,
            counterpartyOId,
            risk: risk || RISK_LEVELS.NOT_SET,
            tags: tags || [],
            type,
          }),
        ),
      });
      return profileIdToKeep;
    },
    {
      onSuccess: () => {
        notification.success({ message: `Profiles merged ${profileIdToKeep}` });
        navigate(`/profiles/${profileIdToKeep}`);
      },
      onError: __DEPRECATED__ErrorHandler,
    },
  );

  return (
    <Page>
      <PageHeader iconType="profile">Merge Profiles</PageHeader>
      <Card
        loading={
          isProfileToKeepLoading ||
          isProfileToRemoveLoading ||
          isProfileToKeepCounterpartiesLoading ||
          isProfileToRemoveCounterpartiesLoading
        }
      >
        {step === 0 && (
          <OverviewMerge
            nextStep={() => setStep(step + 1)}
            profileToKeep={profileToKeep}
            profileToRemove={profileToRemove}
          />
        )}
        {step === 1 && (
          <CounterpartiesConflictsResolver
            nextStep={() => setStep(step + 1)}
            previousStep={step > 0 ? () => setStep(step - 1) : undefined}
            profileToKeep={profileToKeep}
            profileToKeepCounterparties={profileToKeepCounterparties}
            profileToRemove={profileToRemove}
            profileToRemoveCounterparties={profileToRemoveCounterparties}
            relationsToUpdate={relationsToUpdate}
            setRelationsToUpdate={setRelationsToUpdate}
          />
        )}
        {step === 2 && (
          <QuestionnaireConflictsResolver
            informationToKeep={informationToKeep}
            nextStep={() => setStep(step + 1)}
            previousStep={step > 1 ? () => setStep(step - 1) : undefined}
            profileToKeep={profileToKeep}
            profileToRemove={profileToRemove}
            setInformation={setInformation}
          />
        )}
        {step === 3 && (
          <List
            bordered
            dataSource={[
              `update ${profileToKeep.name}'s information`,
              `remove duplicated relations between ${profileToRemove.name} and ${profileToKeep.name}`,
              `tranfer relations from ${profileToRemove.name} to ${profileToKeep.name}`,
              "convert proposedContacts to invite if necessary",
              "transfer invites",
              "merge proposedByUserOIds",
              "transfer Due Diligence Report",
              "transfer Due Diligence Request",
              "transfer Due Diligence Campaign",
              "transfer broadcasts",
              "transfer broadcast requests",
              "transfer correspondences",
              "transfer files' owners",
              "transfer users",
              `delete the profile of ${profileToRemove.name}`,
              "send mail to advertise that the profile was merged only if the profile is proposed",
              `you will be redirected to profile page of ${profileToKeep.name} if everything went well`,
            ]}
            footer={
              <Button
                mr="15px"
                mt="50px"
                onClick={onMerge}
                type="primary"
                width="150px"
              >
                MERGE
              </Button>
            }
            header={"List of the actions required to merge profiles"}
            renderItem={(item) => (
              <List.Item style={{ marginLeft: 40 }}>- {item}</List.Item>
            )}
          />
        )}
      </Card>
    </Page>
  );
}

export default NewProfilePage;
