import { Form, Input } from "antd";
import { useNavigate } from "react-router-dom";
import { validate as validateUuid } from "uuid";
import React, { useState } from "react";

import { Button, Modal } from "@gfw/corvus";

function ActionMergeProfiles({ profile }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);
  const handleSubmit = ({ profileId }) =>
    navigate(`/profiles/merge/${profile.profileId}/${profileId}`);

  return (
    <>
      <Modal
        autoClose={false}
        hideFooterBorder
        hideHeaderBorder
        onCancel={handleCancel}
        onOk={form.submit}
        title="Merge profiles"
        trigger={
          <Button block disabled icon="password" mb="md" onClick={showModal}>
            Merge profiles
          </Button>
        }
        visible={isModalVisible}
        width="30%"
      >
        <Form
          autoComplete="off"
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Please set the profileId of the profile you want to merge with. The current profile will be removed!"
            name="profileId"
            required={false}
            rules={[
              {
                required: true,
                message: "Please type a profile id",
              },
              {
                validator(_, value) {
                  if (!validateUuid(value)) {
                    return Promise.reject(
                      new Error("Please type a valid profile id"),
                    );
                  }
                  if (value === profile.profileId) {
                    return Promise.reject(
                      new Error("You cannot merge this profile to itself"),
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            validateFirst={true}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ActionMergeProfiles;
