import { CopyToClipboard } from "react-copy-to-clipboard";
import { Flex, Result, Tag, Typography } from "antd";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useQuery } from "react-query";
import React, { useEffect } from "react";
import styled from "styled-components";

import {
  __DEPRECATED__ErrorHandler,
  Button,
  Card,
  FONT_SIZES,
  Icon,
  Loading,
  NavigateLink,
  notification,
  Space,
  Tabs,
} from "@gfw/corvus";
import { apiClient } from "@gfw/backend-connector";
import { PROFILE_STATE, profileStateToString } from "@gfw/core";

import { PageTitle, PageTitleTypo } from "@src/resources/generic/components";

import {
  ProfileCounterparties,
  ProfileDetail,
  ProfileMergeHistory,
  ProfileStats,
  ProfileUsers,
} from "./screens";

const { Text } = Typography;

const StyledId = styled(Text)`
  font-size: ${FONT_SIZES.xs};
`;

function CopyId({ profileId }) {
  return (
    <CopyToClipboard
      onCopy={() => notification.success({ message: "Copied to clipboard" })}
      text={profileId}
    >
      <Button icon="copy" tooltip="Copy to clipboard" type="link"></Button>
    </CopyToClipboard>
  );
}

const ProfilePageTitle = function ProfilePageTitle({ profile }) {
  const { name, profileId, isPremium, state } = profile;

  let color = "grey";
  let label = profileStateToString(state);
  if (state === PROFILE_STATE.DRAFT) {
    color = "blue";
  } else if (state === PROFILE_STATE.PROPOSED) {
    color = "purple";
  } else if (state === PROFILE_STATE.INACTIVE) {
    color = "red";
  } else if (state === PROFILE_STATE.OFFICIAL) {
    color = "green";
  }
  return (
    <PageTitle>
      <Space align="baseline" size="small">
        <Icon type="profile" />
        <Flex vertical>
          <PageTitleTypo> {name} </PageTitleTypo>
          <Flex align="center">
            <StyledId>{profileId}</StyledId>
            <CopyId profileId={profileId} />
          </Flex>
        </Flex>
        <Flex>
          {isPremium && <Tag color={"#42a5ac"}>premium</Tag>}
          <Tag color={color}>{label}</Tag>
        </Flex>
      </Space>
    </PageTitle>
  );
};

function ProfilePage() {
  const { id } = useParams();
  const tab = useParams()["*"];
  const navigate = useNavigate();

  const {
    data: profile,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["profile", id],
    queryFn: async () => {
      const response = await apiClient.get(`/profiles/${id}`);
      return response?.data;
    },
    onError: __DEPRECATED__ErrorHandler,
    refetchOnWindowFocus: false,
  });

  let items = [
    {
      label: (
        <Flex align="center">
          <Icon fs="lg" mr="sm" type="detail" />
          Profile
        </Flex>
      ),
      key: "details",
    },
    {
      label: (
        <Flex align="center">
          <Icon fs="lg" mr="sm" type="counterparties" />
          Counterparties
        </Flex>
      ),
      key: "counterparties",
    },
  ];
  if (profile?.state !== PROFILE_STATE.PROPOSED) {
    items.push({
      label: (
        <Flex align="center">
          <Icon fs="lg" mr="sm" type="users" />
          Users
        </Flex>
      ),
      key: "users",
    });
  }
  items.push({
    label: (
      <Flex align="center">
        <Icon fs="lg" mr="sm" type="queryStats" />
        Stats
      </Flex>
    ),
    key: "stats",
  });
  if (profile?.mergeHistory?.length) {
    items.push({
      label: (
        <Flex align="center">
          <Icon fs="lg" mr="sm" type="info" />
          Merge Info
        </Flex>
      ),
      key: "merge-history",
    });
  }

  const onChange = (tabId) => {
    navigate(`/profiles/${id}/${tabId}`);
  };

  useEffect(() => {
    refetch();
  }, [id, refetch]);

  if (isLoading) {
    return (
      <Flex align="center" justify="center" style={{ height: "80vh" }}>
        <Loading size="large" />
      </Flex>
    );
  }

  if (!profile) {
    return (
      <Card>
        <Result
          extra={
            <Flex justify="center">
              <NavigateLink to="/profiles">Back</NavigateLink>
            </Flex>
          }
          status="404"
          subTitle="Sorry, this profile is not available in our system"
          title="Profile not found"
        />
      </Card>
    );
  }

  return (
    <>
      <ProfilePageTitle profile={profile} />
      <Card tabs>
        <Tabs activeKey={tab} items={items} onChange={onChange} />
        <Routes>
          <Route
            element={
              <ProfileCounterparties
                actions={{ refetch }}
                isLoading={isLoading}
                profile={profile}
              />
            }
            path="counterparties"
          />
          {profile.state !== PROFILE_STATE.PROPOSED && (
            <Route
              element={<ProfileUsers profile={profile} state={profile.state} />}
              path="users"
            />
          )}
          <Route element={<ProfileStats profile={profile} />} path="stats" />
          <Route
            element={<ProfileMergeHistory profile={profile} />}
            path="merge-history"
          />
          <Route
            element={<ProfileDetail profile={profile} refetch={refetch} />}
            path="details"
          />
          <Route element={<Navigate to="details" />} index />
        </Routes>
      </Card>
    </>
  );
}

export default ProfilePage;
